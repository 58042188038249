<template>
  <div class="source-editor">
    <codemirror :options="editorOptions" v-model="source2" @changes="sourceChanged"/>
  </div>
</template>

<script>
import { codemirror } from 'vue-codemirror'

import 'codemirror/mode/javascript/javascript'
import 'codemirror/lib/codemirror.css'
import '@/assets/darcula.css'

export default {
  name: 'SourceEditor',

  props: {
    source: {
      type: String,
      required: true
    }
  },

  components: {
    codemirror
  },

  methods: {
    sourceChanged(editor, changes) {
      if (changes[0].origin !== 'setValue') {
        this.$emit('sourcechanged', this.source2)
      }
    }
  },

  watch: {
    source() {
      this.source2 = this.source
    }
  },

  data() {
    return {
      source2: '',
      
      editorOptions: {
        tabSize: 4,
        indentUnit: 4,
        mode: {
          name: 'javascript',
          json: true
        },
        theme: 'darcula',
        lineNumbers: true,
        height: '100%'
      }
    }
  },

  mounted() {
    this.source2 = this.source
  }
}
</script>

<style>
.CodeMirror {
  height: 100%;
}
</style>
