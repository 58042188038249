var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('draggable',{staticClass:"objects-list",attrs:{"list":_vm.list,"handle":".grip","animation":"500"}},_vm._l((_vm.list),function(item){return _c('div',{key:item._rid,staticClass:"objects-list-item",class:{
      'objects-list-item--no-name': !item.name,
      'objects-list-item--active': item._rid === _vm.selectedObject._rid
    },on:{"click":function($event){return _vm.$emit('objectselected', item)}}},[_c('div',{staticClass:"grip"},[_c('i',{staticClass:"fal fa-grip-lines"})]),_c('div',{staticClass:"icon"},[_c('i',{staticClass:"fal",class:{
        'fa-wind': item.type === 'airTank',
        'fa-tachometer-fast': item.type === 'atis',
        'fa-tachometer-alt': item.type === 'ecb',
        'fa-tachometer-slow': item.type === 'euroAtis',
        'fa-thermometer-three-quarters': item.type === 'hubTemp',
        'fa-tire': item.type === 'tire',
        'fa-text': item.type === 'text'
      }})]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"description"},[(item.hasOwnProperty('sensor'))?_c('span',[_vm._v(" Sensor "+_vm._s(Array.isArray(item.sensor) ? item.sensor.join(' and ') : item.sensor)+" ")]):_vm._e()])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Remove Object'),expression:"'Remove Object'"}],staticClass:"delete",on:{"click":function($event){return _vm.$emit('objectdeleted', item)}}},[_c('i',{staticClass:"fal fa-trash"})])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }