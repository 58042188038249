<template>
  <div class="top-bar">
    <div class="menu">
      <div class="menu-group">
        <div class="menu-item" v-tooltip="'New Layout'" @click="newLayout">
          <i class="fal fa-file-plus"></i>
        </div>
        <div class="menu-item" v-tooltip="'Load File'" @click="getFileS3">
          <i class="fal fa-folder-open"></i>
        </div>
        <div :class="{ 'disabled-container': disabledSaveButton }" class="menu-item" v-tooltip="'Save Layout'" @click="$emit('action', 'saveLayoutToS3')">
          <i class="fal fa-save"></i>
        </div>
        <div class="menu-separator"></div>
        <div class="menu-item" v-tooltip="'Add Tire'" @click="$emit('action', 'addTire')">
          <i class="fal fa-tire"></i>
        </div>
        <div class="menu-item" v-tooltip="'Add Hub Temp'" @click="$emit('action', 'addHubTemp')">
          <i class="fal fa-thermometer-three-quarters"></i>
        </div>
        <div class="menu-item" v-tooltip="'Add ATIS'" @click="$emit('action', 'addATIS')">
          <i class="fal fa-tachometer-fast"></i>
        </div>
        <div class="menu-item" v-tooltip="'Add ECB'" @click="$emit('action', 'addECB')">
          <i class="fal fa-tachometer-alt"></i>
        </div>
        <div class="menu-item" v-tooltip="'Add Euro ATIS'" @click="$emit('action', 'addEuroATIS')">
          <i class="fal fa-tachometer-slow"></i>
        </div>
        <div class="menu-item" v-tooltip="'Add Air Tank'" @click="$emit('action', 'addAirTank')">
          <i class="fal fa-wind"></i>
        </div>
        <div class="menu-item" v-tooltip="'Add Text'" @click="$emit('action', 'addText')">
          <i class="fal fa-text"></i>
        </div>
        <div class="menu-separator"></div>
        <div class="menu-item" v-tooltip="'Reindex Sensors'" @click="$emit('action', 'reindexSensors')">
          <i class="fal fa-sort-numeric-down"></i>
        </div>
      </div>
      <div class="menu-group">
        <div class="menu-item" v-tooltip="'Logout'" @click="$emit('signout')">
          <i class="fal fa-sign-out"></i>
        </div>
      </div>
      <div v-if="showModal">
        <div class="overlay">
          <div class="master">
            <div class="title-modal">
              <div class="title"> Open Layout</div>
              <div class="modal-close">
                <button @click="closeModal">
                  <i class="fal fa-times"></i>
                </button>
              </div>
            </div>
            <div class="modal"> 
              <div class="group-container" v-for="(items, groupName) in groupedLayouts" :key="groupName">
                <div class="group-header">
                  {{ groupName }}
                </div>
                <div class="group-content">
                  <div class="items-modal" 
                    :class="{ active: selectedLayout === item}" 
                    v-for="item in items" :key="item.id" 
                    @dblclick="openSelectedLayout(item.id)" 
                    @click="selectLayout(item)"
                  >
                    <div class="layout-icon">
                      <i :class="item.iconClass"></i>
                    </div>
                    <div class="layout-name">
                      {{ item.name }}
                    </div> 
                    <div class="layout-id">
                      {{ item.id }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer-modal">
              <div class="count">{{ quantity }} layouts</div>
              <div class="btns">
                <!-- <button @click="deleteSelectedLayout">Delete</button>  -->
                <button @click="openSelectedLayout(selectedLayout.id)">Open</button>
              </div>
            </div>  
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import { getNextAvailableId, listFilesS3, saveFileToS3, moveFileToTrash } from '../libs/s3Utils'

export default {
  name: 'TopBar',

  props: {
    layouts: {
      type: Object,
      required: true
    },
    openLayout: Function
  },

  data() {
    return {
      showModal: false,
      allLayouts: [],
      fileName: '',
      selectedLayout: null,
      quantity: 0,
      disabledSaveButton: false
    }
  },

  mounted() {
    window.addEventListener('keydown', event => {
      if(event.key === 'Escape') {
        this.closeModal()
      }
    })

    this.getFileS3(false)
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.closeModal)
  },

  methods: {
    newLayout() {
      this.selectedLayout = null
      if(this.$store.state.lastGraphicId === -1){
        const nextId = getNextAvailableId(this.allLayouts)
        this.$store.state.lastGraphicId = nextId
      }
      this.$store.commit('setGraphicId')
      this.$emit('action', 'reset')
    },

    getFileS3(modal=true) {
      listFilesS3()
        .then(layouts  => {
          this.allLayouts = layouts
          this.showModal = modal
          this.quantity = layouts.length
        })
        .catch(error => {
          console.error('Error loading files from S3:', error)
        })
    },
    
    async saveLayoutToS3() {
      this.disabledSaveButton = true
  
      const pathS3 = process.env.VUE_APP_JSON_DESTINATION_PATH
      
      const layoutParse = this.$store.getters.superCleanGraphic
      const getKey = Object.keys(layoutParse)[0]
      const getId = layoutParse[getKey]
      
      const fileName = this.fileName
      const isNewLayout = !this.selectedLayout
      
      const s3Key = fileName ? pathS3 + fileName : pathS3 + `${getId}.tiregraphic.json`
      
      if(isNewLayout && this.allLayouts.some(item => item.id === getId)) {
        alert ('This ID already exists')
        return
      }

      layoutParse.description = layoutParse.description.trim()
      
      const layoutData = JSON.stringify(layoutParse)
      
      await saveFileToS3(s3Key, layoutData)
      
      this.allLayouts.push({ id: getId, fileName })
      
      const nextId = getNextAvailableId(this.allLayouts)
      this.$store.state.lastGraphicId = nextId

      setTimeout(() => {
        this.disabledSaveButton = false
       }, 5000)
    },

    selectLayout(id) {
      this.selectedLayout = id
    },

    openSelectedLayout(layout) {
      const layoutId = typeof layout === 'object' ? layout.id : layout
      const selectedFile = this.allLayouts.find(file => file.id === layoutId)

      if(selectedFile) {
        const fullFilePath = selectedFile.fullFilePath
        
        this.$emit('open-layout', fullFilePath)
        this.closeModal(true)
      }
    },

    deleteSelectedLayout() {
      if(this.selectedLayout) {
        const layoutId = this.selectedLayout.id
        const sourceKey = this.selectedLayout.filePath
        const trashKey = `${process.env.VUE_APP_JSON_TRASH_PATH}${layoutId}.tiregraphic.json`

        try {
          moveFileToTrash(sourceKey, trashKey)
         
          this.allLayouts = this.allLayouts.filter(item => item.id !== layoutId)
          this.selectedLayout = null
        } catch(e) {
          console.error('Error deleting: ', e)
        }
      }
    },

    closeModal() {
      this.showModal = false
    },
  },

  computed: {
    groupedLayouts() {
      return _(this.allLayouts).sortBy('id').groupBy('group').value()
    }
  }
}
</script>

<style lang="less" scoped>
@height: 60px;

.top-bar {
  background-color: darken(@theme-color, 2%);
  
  .menu {
    display: flex;
    padding: 0 10px;
    
    .menu-group {
      flex: 1;
      
      &:nth-child(2) {
        text-align: right;
      }
    }
    .menu-item {
      display: inline-block;
      width: @height;
      height: @height;
      font-size: 22pt;
      color: fade(contrast(@theme-color), 60%);
      padding: 14px;
      cursor: pointer;
      text-align: center;

      &:hover {
        opacity: 1;
        color: @primary-color;
        background-color: fade(@primary-color, 10%);
      }

      &:active {
        opacity: 0.5;
      }
    }

    .menu-separator {
      display: inline-block;
      height: @height;
      margin: 0 10px;
      border: 1px solid fade(contrast(@theme-color), 3%);
      vertical-align: bottom;
    }

    .disabled-container {
      opacity: 0.5; 
      pointer-events: none;
    }
  }
}
.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;

    .master {
      display: flex;
      background-color: #0F1011;
      flex-direction: column;
      height: 550px;
      width: 700px;
      justify-content: center;
      align-items: center;
    }
    .title-modal {
      display: flex;
      padding: 10px;
      color: #FFF;
      width: 100%;

      > div {
        flex: 1;
      }
    }
    .title {
      display: flex;
      align-items: center;
    }
    .modal-close {
      text-align: right;

      button {
        font-size: 14pt;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        border: 2px solid transparent;
        background-color: #282829;
        color: #FFF;
        cursor: pointer;
        
        &:hover {
          color: #F25020;
          border-color: #F25020;
        }
      } 
    }
    .modal {
      flex: 1;
      overflow: auto;
      display: flex; 
      flex-wrap: wrap;
      gap: 5px;  
      justify-content: center;

    }
    .group-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px; 
    }
    .group-header {
      display: flex;
      justify-content: center;
      width: 100%; 
      margin-bottom: 10px;
      font-size: 20pt;
      opacity: 0.5;
      font-weight: 600;
      color: #FFF;
      text-transform: uppercase;
    }
    .group-content {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
    }
    .items-modal {
      display: flex;
      flex: 0 0 160px;
      padding: 15px;
      border-radius: 10px;
      justify-content: center;
      flex-direction: column;
      border: 2px solid transparent;
      text-align: center;
      cursor: pointer;
      background-color: #282829;
      color: #FFF;

        &:hover {
          color: #F25020;
          border-color: #F25020;
        }
    }
    .active {
       color: #F25020;
       border-color: #F25020;
    } 
    .layout-icon {
      flex: 0 0 50px;
      font-size: 25pt;
    }
    .layout-name {
      flex: 1;
      font-size: 10pt;
      margin-bottom: 2px;
    }
    .layout-id {
      font-size: 10pt;
      opacity: 0.6;
    } 
    .footer-modal {
      display: flex;
      width: 100%;
      padding: 15px;

      > div {
        flex: 1;
      }
      .count {
        display: flex;
        align-items: center;
        opacity: 0.5;
        font-size: 11pt;
        color: #FFF;
      }
      .btns {
        text-align: right;
        
        button {
          padding: 15px 25px;
          margin-left: 5px;
          cursor: pointer;
          background-color: #282829;
          color: #fff;
          border-radius: 8px;
          border: 1px solid transparent;
          font-size: 11pt;
          font-weight: bold;
         
          &:hover {
            color: #F25020;
            border-color: #F25020;
          }
        }
      }
    }
}
</style>
