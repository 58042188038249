<template>
  <div class="properties-editor">
    <div v-if="properties.layout" class="property-list">
      <div class="property-item">
        <label>ID</label>
        <input type="number" min="0" v-model.number="properties.id" disabled>
      </div>
      
      <div class="property-item">
        <label>Type</label>
        <select v-model="properties.type">
          <option value="tractor">Tractor</option>
          <option value="trailer">Trailer</option>
        </select>
      </div>
      
      <div class="property-item">
        <label>Group</label>
        <select v-model="properties.group">
          <option value="trucks">Trucks</option>
          <option value="tractors">Tractors</option>
          <option value="trailers">Trailers</option>
          <option value="tractor_trailers">Tractors With Trailer</option>
          <option value="rvs">RVs</option>
          <option value="randd">R&D</option>
          <option value="bus">Bus</option>
          <option value="dolly">Dolly</option>
          <option value="atis">ATIS</option>
          <option value="waste_trucks">Waste Trucks</option>
          <option value="others">Others</option>
        </select>
      </div>
      
      <div class="property-item">
        <label>Description</label>
        <input type="text" v-model="properties.description">
      </div>
      
      <div class="property-item">
        <label>Image</label>
        <div class="select-section">
          <div>
            <select v-model="properties.layout.image">
              <option value="">Select an Image</option>
              <option v-for="image in imageList" :key="image.imagePath" :value="image.withExtension">{{ image.withoutExtension }}</option>
            </select>
          </div>
          <div class="add-image">
            <input type="file" ref="fileInput" @change="handleFileUpload" style="display: none">
            <div class="add-btn" v-tooltip="'Add an image'" @click="$refs.fileInput.click()">
              <i class="fal fa-plus"></i>
            </div>
          </div>
        </div>
      </div>
      
      <div class="property-item">
        <label>Attachments</label>
        <label class="checkbox">
          <input type="checkbox" value="atis" v-model="properties.attachments">
          <span>ATIS</span>
        </label>
        <label class="checkbox">
          <input type="checkbox" value="ecb" v-model="properties.attachments">
          <span>ECB</span>
        </label>
        <label class="checkbox">
          <input type="checkbox" value="airTank" v-model="properties.attachments">
          <span>Air Tank</span>
        </label>
        <label class="checkbox">
          <input type="checkbox" value="euroAtis" v-model="properties.attachments">
          <span>Euro ATIS</span>
        </label>
        <label class="checkbox">
          <input type="checkbox" value="hubTemp" v-model="properties.attachments">
          <span>Hub Temp</span>
        </label>
        <label class="checkbox">
          <input type="checkbox" value="inputsOnly" v-model="properties.attachments">
          <span>Inputs Only</span>
        </label>
        <label class="checkbox">
          <input type="checkbox" value="liftgate" v-model="properties.attachments">
          <span>Liftgate</span>
        </label>
      </div>

      <div class="property-section">
        <div class="title">Alternative Layout</div>

        <div class="property-item">
          <label>Input</label>
          <input type="text" placeholder="(Disabled)" v-model="properties.altLayout.input">
        </div>

        <div class="property-item"> 
          <label>Image</label>
          <select v-model="properties.altLayout.image">
            <option value="">Select an Image</option>
            <option v-for="image in imageList" :key="image.imagePath" :value="image.withExtension">{{ image.withoutExtension }}</option>
          </select>
        </div>   
      </div>

      <div class="property-section">
        <div class="title">Tire Defaults</div>

        <div class="property-item">
          <label>Width</label>
          <input type="number" min="0" placeholder="(Default)" v-model.number="properties.layout.defaults.tire.width">
        </div>
        <div class="property-item">
          <label>Height</label>
          <input type="number" min="0" placeholder="(Default)" v-model.number="properties.layout.defaults.tire.height">
        </div>
        <div class="property-item">
          <label>Border Radius</label>
          <input type="number" min="0" placeholder="(Default)" v-model.number="properties.layout.defaults.tire.borderRadius">
        </div>
      </div>

      <div class="property-section">
        <div class="title">Hub Temp Defaults</div>

        <div class="property-item">
          <label>Size</label>
          <input type="number" min="0" placeholder="(Default)" v-model.number="properties.layout.defaults.hubTemp.size">
        </div>
      </div>
    </div>

    <div v-if="properties.type === 'atis'" class="property-list">
      <div class="property-list-title">ATIS</div>

      <div class="property-item">
        <label>Name</label>
        <input type="text" v-model="properties.name">
      </div>
      <div class="property-item">
        <label>X</label>
        <input type="number" min="0" v-model.number="properties.x">
      </div>
      <div class="property-item">
        <label>Y</label>
        <input type="number" min="0" v-model.number="properties.y">
      </div>
      <div class="property-item">
        <label>Size</label>
        <input type="number" min="0" placeholder="(Default)" v-model.number="properties.size">
      </div>
      <div class="property-item">
        <label>Sensor IN</label>
        <input type="number" min="0" :max="maxSensors" v-model.number="properties.sensor[0]">
      </div>
      <div class="property-item">
        <label>Sensor OUT</label>
        <input type="number" min="0" :max="maxSensors" v-model.number="properties.sensor[1]">
      </div>
    </div>
    
    <div v-if="properties.type === 'ecb'" class="property-list">
      <div class="property-list-title">ECB</div>

      <div class="property-item">
        <label>Name</label>
        <input type="text" v-model="properties.name">
      </div>
      <div class="property-item">
        <label>X</label>
        <input type="number" min="0" v-model.number="properties.x">
      </div>
      <div class="property-item">
        <label>Y</label>
        <input type="number" min="0" v-model.number="properties.y">
      </div>
      <div class="property-item">
        <label>Size</label>
        <input type="number" min="0" placeholder="(Default)" v-model.number="properties.size">
      </div>
    </div>

    <div v-if="properties.type === 'airTank'" class="property-list">
      <div class="property-list-title">Air Tank</div>

      <div class="property-item">
        <label>Name</label>
        <input type="text" v-model="properties.name">
      </div>
      <div class="property-item">
        <label>X</label>
        <input type="number" min="0" v-model.number="properties.x">
      </div>
      <div class="property-item">
        <label>Y</label>
        <input type="number" min="0" v-model.number="properties.y">
      </div>
      <div class="property-item">
        <label>Size</label>
        <input type="number" min="0" placeholder="(Default)" v-model.number="properties.size">
      </div>
      <div class="property-item">
        <label>Sensor</label>
        <input type="number" min="0" :max="maxSensors" v-model.number="properties.sensor">
      </div>
    </div>

    <div v-if="properties.type === 'euroAtis'" class="property-list">
      <div class="property-list-title">Euro ATIS</div>

      <div class="property-item">
        <label>Name</label>
        <input type="text" v-model="properties.name">
      </div>
      <div class="property-item">
        <label>X</label>
        <input type="number" min="0" v-model.number="properties.x">
      </div>
      <div class="property-item">
        <label>Y</label>
        <input type="number" min="0" v-model.number="properties.y">
      </div>
      <div class="property-item">
        <label>Size</label>
        <input type="number" min="0" placeholder="(Default)" v-model.number="properties.size">
      </div>
      <div class="property-item">
        <label>Sensor</label>
        <input type="number" min="0" :max="maxSensors" v-model.number="properties.sensor">
      </div>
    </div>

    <div v-if="properties.type === 'hubTemp'" class="property-list">
      <div class="property-list-title">Hub Temp</div>

      <div class="property-item">
        <label>Name</label>
        <input type="text" v-model="properties.name">
      </div>
      <div class="property-item">
        <label>X</label>
        <input type="number" min="0" v-model.number="properties.x">
      </div>
      <div class="property-item">
        <label>Y</label>
        <input type="number" min="0" v-model.number="properties.y">
      </div>
      <div class="property-item">
        <label>Size</label>
        <input type="number" min="0" placeholder="(Default)" v-model.number="properties.size">
      </div>
      <div class="property-item">
        <label>Sensor</label>
        <input type="number" min="0" :max="maxSensors" v-model.number="properties.sensor">
      </div>
    </div>

    <div v-if="properties.type === 'tire'" class="property-list">
      <div class="property-list-title">Tire</div>

      <div class="property-item">
        <label>Name</label>
        <input type="text" v-model="properties.name">
      </div>

      <div class="property-item">
        <label>Tire Type</label>
        <select v-model="properties.tireType">
          <option value="tireSteer">Steer</option>
          <option value="tireDrive">Drive</option>
          <option value="tireTrailer">Trailer</option>
          <option value="tireTag">Tag</option>
          <option value="tireSpare">Spare</option>
          <option value="tire">Tire</option>
        </select>
      </div>

      <div class="property-item">
        <label>X</label>
        <input type="number" min="0" v-model.number="properties.x">
      </div>

      <div class="property-item">
        <label>Y</label>
        <input type="number" min="0" v-model.number="properties.y">
      </div>

      <div class="property-item">
        <label>Width</label>
        <input type="number" min="0" placeholder="(Default)" v-model.number="properties.width">
      </div>

      <div class="property-item">
        <label>Height</label>
        <input type="number" min="0" placeholder="(Default)" v-model.number="properties.height">
      </div>

      <div class="property-item">
        <label>Border Radius</label>
        <input type="number" min="0" placeholder="(Default)" v-model.number="properties.borderRadius">
      </div>

      <div class="property-item">
        <label>Sensor</label>
        <input type="number" min="0" :max="maxSensors" v-model.number="properties.sensor">
      </div>

      <div class="property-item">
        <label>Geotab Pressure Diagnostic Code</label>
        <input type="number" min="0" v-model.number="properties.geotabPressCode">
      </div>

      <div class="property-item">
        <label>Geotab Temperature Diagnostic Code</label>
        <input type="number" min="0" v-model.number="properties.geotabTempCode">
      </div>

      <div class="property-item">
        <label>Geotab Sensor Index</label>
        <input type="number" min="0" v-model.number="properties.geotabIndex">
      </div>

     
    </div>

    <div v-if="properties.type === 'text'" class="property-list">
      <div class="property-list-title">Text</div>

      <div class="property-item">
        <label>Text</label>
        <input type="text" v-model="properties.text">
      </div>
      <div class="property-item">
        <label>Font</label>
        <select v-model="properties.font">
          <option value="Arial">Arial</option>
          <option value="Courier New">Courier New</option>
          <option value="Consolas">Consolas</option>
          <option value="Helvetica">Helvetica</option>
          <option value="Tahoma">Tahoma</option>
          <option value="Times New Roman">Times New Roman</option>
          <option value="Verdana">Verdana</option>
          <option value="sans-serif">(generic) Sans-serif</option>
          <option value="serif">(generic) Serif</option>
        </select>
      </div>
      <div class="property-item">
        <label>Size</label>
        <input type="number" min="0" v-model.number="properties.size">
      </div>
      <div class="property-item">
        <label>Color</label>
        <input type="color" v-model.number="properties.color">
      </div>
      <div class="property-item">
        <label>Style</label>
        <select v-model="properties.style">
          <option value="normal">Normal</option>
          <option value="bold">Bold</option>
        </select>
      </div>
      <div class="property-item">
        <label>Rotation</label>
        <input type="number" max="360" min="-360" v-model.number="properties.rotation" placeholder="(Default)">
      </div>
      <div class="property-item">
        <label>X</label>
        <input type="number" min="0" v-model.number="properties.x">
      </div>
      <div class="property-item">
        <label>Y</label>
        <input type="number" min="0" v-model.number="properties.y">
      </div>
    </div>
  </div>
</template>

<script>
import { getNextAvailableId, listFilesS3, listImagesS3, saveImageToS3 } from '../libs/s3Utils'

export default {
  name: 'PropertiesEditor',

  props: {
    properties: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      maxSensors: 32,
      imageList: [],
      selectedFile: null,
      allLayouts: [],
      disabledSaveButton: false
    }
  },

  methods: {
    checkIfIdExists() {
      this.$emit('idChanged', this.properties.id)
    },

    loadImages() {
      setTimeout(() => {
        listImagesS3().then((images) => {
          this.imageList = images
        }).catch((error) => {
          console.error('Erro ao listar imagens do S3:', error)
        })
      }, 2000)
    },

    handleFileUpload() {
      this.selectedFile = this.$refs.fileInput.files[0]

      if(this.selectedFile) {
        this.validateAndUploadImage(this.selectedFile)
      }
    },

    async isPng(file) {
      if (!file.name.toLowerCase().endsWith('.png')) {
        return false
      }   

      return new Promise( resolve => {
        const reader = new FileReader()
        
        reader.onloadend = function(event) {
          const arr = (new Uint8Array(event.target.result)).subarray(0, 8)
          let isPngFile = true

          const pngSignature = [0x89, 0x50, 0x4E, 0x47, 0x0D, 0x0A, 0x1A, 0x0A]

          for (let i = 0; i < pngSignature.length; i++) {
            if (arr[i] !== pngSignature[i]) {
              isPngFile = false
              break
            }
          }

          if (isPngFile) {
            resolve(true)
          } else {
            resolve(false)
          }
        }
        
        reader.readAsArrayBuffer(file)
      })

    },

    async validateAndUploadImage(file) {
      if(await this.isPng(file)) {
        this.uploadImage()
      } else {
        alert('The file must be in PNG image format!')
        this.selectedFile = null
      }
    },

    uploadImage() {
      if(this.selectedFile) {
        saveImageToS3(this.selectedFile.name, this.selectedFile) 
          .then(() => {
            alert('Image sent successfully!')
            
            listImagesS3().then((imageList) => {
              this.imageList = imageList
            }).catch((error) => {
              console.error('Error updating image list', error)
            })
          })
          .catch(error => {
            console.log('Error sending image', error)
          })
      } else {
        alert('No file selected')
      }
    },

    setNextAvailableId() {
      const nextId = getNextAvailableId(this.allLayouts)
      this.$store.commit('setGraphicId', nextId)
    }
  },

  mounted() {
    this.loadImages()

    listFilesS3().then(layouts => {
      this.allLayouts = layouts
      this.setNextAvailableId()
    }).catch(e => {
      console.error(e)
    })
  }
}
</script>

<style lang="less" scoped>
.properties-editor {
  padding: 20px;
  
  .property-list {
    .property-list-title {
      font-size: 15pt;
      font-weight: bold;
      color: shade(@primary-color, 30%);
    }

    .property-item {
      padding: 10px 0;

      .select-section {
        display: flex;
      }

      > div {
        flex: 1;
      }
      .add-image { 
        margin-left: 15px; 
      }

      .add-btn {
        display: flex;
        width: 40px;
        height: 40px;
        padding: 10px;
        font-size: 15pt;
        border: 2px solid transparent;
        background-color: shade(@primary-color, 40%);
        color: contrast(@primary-color);
        border-radius: 50px;
        cursor: pointer;

        &:hover {
          background-color: shade(@primary-color, 20%);
        }

        &:active {
          background-color: shade(@primary-color, 50%);
        }
      }

      &:focus-within {
        label {
          color: @primary-color;
        }
      }

      label {
        display: block;
        color: fade(contrast(@theme-color), 50%);
        text-transform: uppercase;
        font-family: 'Roboto Condensed';
        font-size: 10pt;
        font-weight: bold;
        padding-bottom: 5px;

        &.checkbox {        
          span {
            margin-left: 5px;
            font-size: 12pt;
            color: fade(contrast(@theme-color), 50%) !important;
          }
        }
      }

      input[type='text'],
      input[type='number'],
      select {
        width: 100%;
        padding: 10px;
        background-color: transparent;
        border: 1px solid fade(contrast(@theme-color), 5%);
        outline: none;
        color: contrast(@theme-color);
        font-family: Roboto;

        &:focus {
          border-color: @primary-color;
          background-color: fade(@primary-color, 5%);
        }

        option {
          color: #000;
        }
      }
    }

    .property-section {
      border: 1px solid fade(@primary-color, 20%);
      padding: 15px 15px 10px 15px;
      position: relative;
      margin-top: 10px;
      margin-bottom: 20px;

      .title {
        position: absolute;
        top: -9px;
        left: 5px;
        font-size: 10pt;
        color: shade(@primary-color, 30%);
        background-color: @theme-color;
        padding: 0 5px;
      }
    }
  }
}
</style>
