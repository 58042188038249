<template>
  <div id="app">
    <SysadminAuthLoadingScreen app-title="DRAWING TOOL" v-if="appState === 'loading'"/>
    <SysadminAuthUnauthorizedAppScreen @signout="signOut" v-if="appState === 'forbidden'"/>
    <TopBar :layouts="layouts" @action="topBarAction" @open-layout="showGraphic" @signout="signOut" ref="topBar"/>
    <div class="container">
      <div class="editor-container">
        <Editor
          ref="editor"
          width="300"
          height="300"
          :layout="cleanGraphic"
          @objectselected="objectSelected"
          @objectmoved="objectMoved"
          @objectdeleted="objectDeleted"/>
      </div>

      <div class="objects-list-container">
        <ObjectsList
          :list="layout.objects"
          :selectedObject="selectedObject"
          @objectselected="objectSelected"
          @objectdeleted="objectDeleted"/>

        <div v-if="layout.objects.length === 0" class="no-objects">
          <i class="fas fa-do-not-enter"></i>
          <h1>No objects</h1>
          <h2>Try adding some objects into your project from the toolbar above</h2>
        </div>
      </div>

      <div class="properties-editor-container">
        <PropertiesEditor :properties="selectedObject"/>
      </div>

      <div class="source-container">
        <SourceEditor :source="source" @sourcechanged="sourceChanged" ref="sourceEditor"/>

        <div class="actions">
          <div class="copy" v-tooltip="'Copy Source Code'" @click="copySourceToClipboard">
            <i class="fal fa-copy"></i>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { authGuard, clearToken, hasPermission } from '@psitireinflation/sysadmin-auth-lib'
import TireGraphic from './libs/TireGraphic'

import AWS from 'aws-sdk'
import _ from 'lodash'

import SysadminAuthLoadingScreen from '@psitireinflation/sysadmin-auth-lib/components/vue2/SysadminAuthLoadingScreen.vue'
import SysadminAuthUnauthorizedAppScreen from '@psitireinflation/sysadmin-auth-lib/components/vue2/SysadminAuthUnauthorizedAppScreen.vue'
import Editor from './components/Editor'
import ObjectsList from './components/ObjectsList'
import PropertiesEditor from './components/PropertiesEditor'
import SourceEditor from './components/SourceEditor'
import TopBar from './components/TopBar'

export default {
  name: 'App',
  
  components: {
    TopBar,
    Editor,
    ObjectsList,
    PropertiesEditor,
    SourceEditor,
    SysadminAuthLoadingScreen,
    SysadminAuthUnauthorizedAppScreen
  },

  methods: {
    objectSelected(object) {
      if (object.layout) {
        this.selectedObject = this.graphic
      } else {
        this.selectedObject = this.layout.objects.find(a => a._rid === object._rid)

        this.$refs.editor.selectObject(object)
      }
    },

    objectMoved(coords) {
      this.selectedObject.x = coords.x
      this.selectedObject.y = coords.y

      this.isDirty = true
    },

    objectDeleted(object) {
      this.layout.objects.forEach((item, i) => {
        if (item._rid === object._rid) {
          this.layout.objects.splice(i, 1)

          this.isDirty = true
        }
      })

      if (this.layout.objects.length === 0) {
        this.selectedObject = this.graphic
      } else {
        let lastObject = _.last(this.layout.objects)

        this.$nextTick().then(() => {
          this.selectedObject = lastObject
          
          this.$refs.editor.selectObject(lastObject)
        })
      }
    },

    topBarAction(action) {
      const self = this

      let rand = TireGraphic.generateRid()

      let maxSensor = _.max(this.layout.objects.map(a => a.sensor).flat())
      let sensor = maxSensor === undefined ? 0 : maxSensor + 1
      let tireType = _.get(_.last(this.layout.objects), 'tireType', 'steerTire')

      const graphicId = this.$store.state.graphic.id

      let reindexSensors = 0

      const Actions = {
        add(options) {
          const object = {
            ...options,
            ...{ _rid: rand }
          }

          self.layout.objects.push(object)
  
          self.$nextTick().then(() => {
            self.selectedObject = object
    
            self.$refs.editor.selectObject(object)
          })

          self.isDirty = true
        },

        reset() {
          self.source = `{\n    "id": ${graphicId},\n    "version": 5,\n    "description": "My Vehicle 1",\n    "visible": true,\n    "type": "tractor",\n    "group": "tractors",\n    "attachments": [],\n    "layout": {\n        "image": "box-truck.png",\n        "objects": []\n    }\n}`
          self.sourceChanged(self.source)
          self.isDirty = false
        },

        saveToS3() {
          self.$refs.topBar.saveLayoutToS3()
          self.isDirty = false
        }
      }

      switch (action) {
        case 'reset':
          Actions.reset()
          
          break

        case 'saveLayoutToS3':
          Actions.saveToS3()
          
          break

        case 'addAirTank':
          Actions.add({
            type: TireGraphic.ObjectType.AIR_TANK,
            name: 'AIR TANK',
            x: 75,
            y: 0,
            sensor
          })

          break

        case 'addATIS':
          Actions.add({
            type: TireGraphic.ObjectType.ATIS,
            name: 'ATIS',
            x: 80,
            y: 0,
            sensor: [sensor, ++sensor]
          })

          break
          
        case 'addECB':
          Actions.add({
            type: TireGraphic.ObjectType.ECB,
            name: 'ECB',
            x: 80,
            y: 0
          })

          break

        case 'addEuroATIS':
          Actions.add({
            type: TireGraphic.ObjectType.EURO_ATIS,
            name: 'Euro ATIS',
            x: 80,
            y: 0,
            sensor: sensor
          })

          break

        case 'addHubTemp':
          Actions.add({
            type: TireGraphic.ObjectType.HUB_TEMP,
            name: 'HUB TEMP ' + sensor,
            x: 95,
            y: 12,
            sensor
          })

          break

        case 'addText':
          Actions.add({
            type: TireGraphic.ObjectType.TEXT,
            text: 'TEXT LABEL',
            font: 'Arial',
            size: 15,
            color: 'black',
            style: 'normal',
            x: 60,
            y: 290
          })

          break

        case 'addTire':
          Actions.add({
            type: TireGraphic.ObjectType.TIRE,
            name: 'TIRE ' + (sensor + 1),
            tireType,
            x: 100,
            y: 230,
            sensor
          })

          break

        case 'reindexSensors':
          this.layout.objects.forEach(item => {
            if (item.type !== 'text') {
              if (_.isArray(item.sensor)) {
                item.sensor[0] = reindexSensors++
                item.sensor[1] = reindexSensors++
              } else {
                item.sensor = reindexSensors++
              }
            }
          })
          break
      }
    },

    sourceChanged(source) {
      try {
        this.graphic = JSON.parse(source)

        this.isDirty = true
      } catch {
        //
      }
    },

    copySourceToClipboard() {
      navigator.clipboard.writeText(this.source).then(() => {
        this.$toasted.show('Source code successfully copied!', { 
          theme: 'toasted-primary', 
          position: 'bottom-right', 
          duration : 5000
        })
      })
    },

    async showGraphic(fullFilePath) {
      try {
        const s3 = new AWS.S3()
        const response = await s3.getObject({
          Bucket: process.env.VUE_APP_BUCKET_NAME,
          Key: fullFilePath
        }).promise()
       
        const fileContent = response.Body.toString('utf-8')
        const graphic = JSON.parse(fileContent)

        this.graphic = graphic
        this.showModal = false
    
      } catch (e) {
      console.error(e)
      } 
    },

    signOut() {
      clearToken()
      location.reload()
    },
},

  watch: {
    graphic: {
      deep: true,
      handler() {
        this.source = JSON.stringify(this.cleanGraphic, null, 4)

        this.$refs.editor.update(this.cleanGraphic)
      }
    }
  },

  computed: {
    graphic: {
      get() {
        return this.$store.state.graphic
      },

      set(value) {
        this.$store.commit('setGraphic', value)
      }
    },

    selectedObject: {
      get() {
        return this.$store.state.selectedObject
      },

      set(value) {
        if (value) {
          this.$store.commit('setSelectedObject', value)
        }
      }
    },

    layout() {
      return this.graphic.layout
    },

    cleanGraphic() {
      return this.$store.getters.cleanGraphic
    },

    superCleanGraphic() {
      return this.$store.getters.superCleanGraphic
    }
  },

  data() {
    return {
      isDirty: false,
      source: JSON.stringify(this.$store.getters.cleanGraphic, null, 4),
      layouts: {},
      fileName: '',
      appState: 'loading'
    }
  },

  mounted() { 
    this.$store.state.selectedObject = this.graphic

    window.addEventListener('beforeunload', e => {
      if (this.isDirty) {
        e.returnValue = 'You have unsaved changes that may be loss if you close this app. Are you sure you want to close this app?'
      }
    })

    authGuard({ appName: 'drawing-tool'})
    .then( () => {
      if(hasPermission('drawing-tool.use')){
        this.appState = 'ready'
      }else {
        this.appState = 'forbidden'
      }
    })
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:700|Roboto:400,700&display=swap')
</style>

<style>
.tooltip {
	display: block !important;
	z-index: 10000;
}

.tooltip .tooltip-inner {
	background: black;
	color: white;
	border-radius: 16px;
	padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
	width: 0;
	height: 0;
	border-style: solid;
	position: absolute;
	margin: 5px;
	border-color: black;
	z-index: 1;
}

.tooltip[x-placement^="top"] {
	margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
	border-width: 5px 5px 0 5px;
	border-left-color: transparent !important;
	border-right-color: transparent !important;
	border-bottom-color: transparent !important;
	bottom: -5px;
	left: calc(50% - 5px);
	margin-top: 0;
	margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
	margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
	border-width: 0 5px 5px 5px;
	border-left-color: transparent !important;
	border-right-color: transparent !important;
	border-top-color: transparent !important;
	top: -5px;
	left: calc(50% - 5px);
	margin-top: 0;
	margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
	margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
	border-width: 5px 5px 5px 0;
	border-left-color: transparent !important;
	border-top-color: transparent !important;
	border-bottom-color: transparent !important;
	left: -5px;
	top: calc(50% - 5px);
	margin-left: 0;
	margin-right: 0;
}

.tooltip[x-placement^="left"] {
	margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
	border-width: 5px 0 5px 5px;
	border-top-color: transparent !important;
	border-right-color: transparent !important;
	border-bottom-color: transparent !important;
	right: -5px;
	top: calc(50% - 5px);
	margin-left: 0;
	margin-right: 0;
}

.tooltip[aria-hidden='true'] {
	visibility: hidden;
	opacity: 0;
	transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
	visibility: visible;
	opacity: 1;
	transition: opacity .15s;
}

.tooltip.info .tooltip-inner {
	background: rgba(0, 68, 153, 0.9);
	color: white;
	padding: 24px;
	border-radius: 5px;
	box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
}

.tooltip.info .tooltip-arrow {
	border-color: rgba(0, 68, 153, 0.9);
}

.tooltip.popover .popover-inner {
	background: #f9f9f9;
	color: black;
	padding: 24px;
	border-radius: 5px;
	box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
}

.tooltip.popover .popover-arrow {
	border-color: #f9f9f9;
}
</style>

<style lang="less">
:root {
  --theme-color: @theme-color;
  --primary-color: @primary-color;
}

body {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0;
  background-color: @theme-color;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: darken(@theme-color, 5%) !important;
  border-radius: 0 !important;
}

::-webkit-scrollbar-thumb:hover {
  background: shade(@primary-color, 50%) !important;
}

::-webkit-scrollbar-track {
  background: lighten(@theme-color, 5%) !important;
  border: none !important;
}
</style>

<style lang="less" scoped>
#app {
  .container {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;

    > div {
      overflow: auto;
      flex: 1;
      position: relative;

      .actions {
        position: fixed;
        bottom: 30px;
        right: 30px;

        > div {
          @size: 50px;

          width: @size;
          height: @size;
          background-color: shade(@primary-color, 40%);
          border-radius: @size / 2;
          font-size: 20pt;
          padding: 10px;
          text-align: center;
          color: contrast(@primary-color);
          cursor: pointer;

          &:hover {
            background-color: shade(@primary-color, 20%);
          }

          &:active {
            background-color: shade(@primary-color, 50%);
          }
        }
      }
    }

    .editor-container {
      padding: 20px;
      overflow: hidden;
      text-align: center;
    }

    .objects-list-container {
      position: relative;
      flex-basis: 300px;

      .no-objects {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        padding: 0 20px;

        i {
          font-size: 50pt;
          color: fade(contrast(@theme-color), 5%);
        }
        
        h1 {
          font-size: 22pt;
          color: fade(contrast(@theme-color), 20%);
          margin: 10px 0;
        }

        h2 {
          font-size: 12pt;
          color: fade(contrast(@theme-color), 10%);
          font-weight: normal;
        }
      }
    }

    .properties-editor-container {
      flex-basis: 300px;
    }

    .source-container {
      flex-basis: 500px;
    }

    .objects-list-container,
    .properties-editor-container,
    .source-container {
      flex-grow: 0;
      flex-shrink: 0;
      border-left: 2px solid fade(contrast(@theme-color), 5%);
    }
  }
}
</style>