<template>
  <draggable class="objects-list" :list="list" handle=".grip" animation="500">
    <div v-for="item in list" :key="item._rid" class="objects-list-item"
      :class="{
        'objects-list-item--no-name': !item.name,
        'objects-list-item--active': item._rid === selectedObject._rid
      }"
      @click="$emit('objectselected', item)">

      <div class="grip">
        <i class="fal fa-grip-lines"></i>
      </div>

      <div class="icon">
        <i class="fal" :class="{
          'fa-wind': item.type === 'airTank',
          'fa-tachometer-fast': item.type === 'atis',
          'fa-tachometer-alt': item.type === 'ecb',
          'fa-tachometer-slow': item.type === 'euroAtis',
          'fa-thermometer-three-quarters': item.type === 'hubTemp',
          'fa-tire': item.type === 'tire',
          'fa-text': item.type === 'text'
        }"></i>
      </div>

      <div class="info">
        <div class="name">{{item.name}}</div>
        <div class="description">
          <span v-if="item.hasOwnProperty('sensor')">
            Sensor {{Array.isArray(item.sensor) ? item.sensor.join(' and ') : item.sensor}}
          </span>
        </div>
      </div>

      <div class="delete" v-tooltip="'Remove Object'" @click="$emit('objectdeleted', item)">
        <i class="fal fa-trash"></i>
      </div>
    </div>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: 'ObjectsList',

  components: {
    draggable
  },

  props: {
    list: {
      type: Array,
      required: false
    },
    selectedObject: {
      type: Object
    }
  },

  data() {
    return {
      axleColors: ['#F46036', '#3891A6', '#FDE74C', '#9BC53D', '#E83151']
    }
  }
}
</script>

<style lang="less" scoped>
.objects-list {
  @height: 55px;

  .objects-list-item {
    position: relative;
    background-color: tint(@theme-color, 2%);
    border-bottom: 1px solid tint(@theme-color, 5%);
    height: @height;
    overflow: hidden;
    color: contrast(@theme-color);
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: tint(@theme-color, 3%);
    }

    &:active {
      background-color: tint(@theme-color, 1.8%);
    }

    &--active {
      background-color: shade(@primary-color, 60%);
      
      * {
        color: contrast(@primary-color);
      }

      &:hover {
        background-color: shade(@primary-color, 50%);
      }

      .delete {
        display: inline-block !important;
      }
    }

    &--no-name {
      .name {
        display: none;
      }

      .description {
        opacity: 0.7 !important;
        font-size: 12pt !important;
      }
    }

    > div {
      display: table-cell;
      height: @height;
      vertical-align: middle;
    }

    .grip {
      font-size: 15pt;
      padding: 0 10px;
      opacity: 0.2;
      cursor: ns-resize;
    }

    .icon {
      margin-right: 10px;
      font-size: 20pt;
      padding-right: 8px;
      opacity: 0.3;
      width: 40px;
      text-align: center;
    }

    .info {
      font-size: 12pt;
      color: contrast(@theme-color);

      .name {
        font-size: 12pt;
        font-weight: bold;
        opacity: 0.7;
      }

      .description {
        font-size: 10pt;
        color: fade(contrast(@theme-color), 30%);

        .axle {
          color: black;
          opacity: 1;
          padding: 1px 6px;
          border-radius: 20px;
        }
      }
    }

    .delete {
      display: none;
      position: absolute;
      color: contrast(@theme-color);
      top: 10px;
      right: 10px;
      font-size: 15pt;
      opacity: 0.2;
      background: contrast(contrast(@primary-color));
      width: 35px;
      height: 35px;
      padding: 6px;
      text-align: center;
      border-radius: 50px;

      &:hover {
        opacity: 0.4;
      }

      &:active {
        opacity: 0.6;
      }
    }
  }
}
</style>
