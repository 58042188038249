import Vue from 'vue'
import Vuex from 'vuex'

import VueTooltip from 'v-tooltip'
import VueToasted from 'vue-toasted'

import App from './App.vue'

import { store } from './store/store'

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(VueTooltip)
Vue.use(VueToasted)

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
