/* eslint-disable */

import $ from 'domtastic'
import get from 'lodash/get'

export default class TireGraphic
{
    static version = 2

    static Attachment = {
        ATIS: "atis",
        AIR_TANK: "airTank",
        EURO_ATIS: "euroAtis",
        HUB_TEMP: "hubTemp",
        INPUTS_ONLY: "inputsOnly",
        LIFTGATE: "liftgate",
        ECB: "ecb"
    }

    static ObjectType = {
        AIR_TANK: "airTank",
        ATIS: "atis",
        EURO_ATIS: "euroAtis",
        HUB_TEMP: "hubTemp",
        TEXT: "text",
        TIRE: "tire",
        ECB: "ecb"
    }

    static ObjectStatus = {
        OK: '#00a859',
        ERROR: '#ed3237',
        WARNING: '#ffcc29',
        DISABLED: '#bdbfc1',
        EURO_ATIS_IGNITION_PHASE: '#1095da',
        EURO_ATIS_NORMAL: '#00b050',
        EURO_ATIS_WARNING: '#e4c129',
        EURO_ATIS_EMERGENCY: '#ff0000',
        EURO_ATIS_NOT_WORKING: '#000'
    }

    static VehicleType = {
        TRACTOR: 'tractor',
        TRAILER: 'trailer'
    }

    drawing = {
        container: null,
        options: null,
        state: {}
    }

    enumerableObjectTypes = [
        this.constructor.ObjectType.AIR_TANK,
        this.constructor.ObjectType.ATIS,
        this.constructor.ObjectType.EURO_ATIS,
        this.constructor.ObjectType.HUB_TEMP,
        this.constructor.ObjectType.TIRE,
        this.constructor.ObjectType.ECB
    ]

    throwError = {
        packageName: 'TireGraphic.js',

        drawNotInitializedError() {
            throw new Error(`[${this.packageName}] Graphics not drawn`);
        },

        sensorNotFound(sensor) {
            throw new Error(`[${this.packageName}] Sensor not found: ${sensor}`);
        },

        objectNotFound(rid) {
            throw new Error(`[${this.packageName}] Object not found: ${rid}`);
        },

        unknownObjectType(type) {
            throw new Error(`[${this.packageName}] Unknown object type: ${type}`);
        }
    }

    graphic = null
    isDrawn = false

    constructor(layout) {
        this.graphic = layout;

        let i = 1;

        this.getEnumerableObjects().forEach(item => {
            item._rid = Math.random().toString(31).substr(-4);
            item.index = i++;
        })
    }

    static generateRid() {
        return Math.random().toString(31).substr(-4);
    }

    hasAttachment(attachment) {
        return this.graphic.attachments.includes(attachment);
    }

    getObjects(filter = 'all') {
        const ret = [];

        this.graphic.layout.objects.forEach(item => {
            if (filter === 'all') {
                ret.push(item);
            } else if (filter === 'enumerable') {
                if (this.enumerableObjectTypes.includes(item.type)) {
                    ret.push(item);
                }
            } else if (filter === 'non-enumerable') {
                if (!this.enumerableObjectTypes.includes(item.type)) {
                    ret.push(item);
                }
            }
        });

        return ret;
    }

    getAxles() {
        let axles = this.getObjects().map(a => a.axle);

        axles = [...new Set(axles)]; // Remove duplicated

        let ret = [];

        axles.forEach(axle => {
            ret.push({
                axle,
                objects: this.getObjects().filter(a => a.axle === axle)
            });
        })

        return ret;
    }

    getEnumerableObjects() {
        return this.getObjects('enumerable');
    }

    getNonEnumerableObjects() {
        return this.getObjects('non-enumerable');
    }

    getObjectBySensor(sensor) {
        let ret = null;

        sensor = Number(sensor);

        this.getEnumerableObjects().some(item => {
            if (Array.isArray(item.sensor)) {
                if (item.sensor.includes(sensor)) {
                    ret = item;
                    return true;
                }
            } else {
                if (Number(item.sensor) === sensor) {
                    ret = item;
                    return true;
                }
            }
        });

        if (!ret) {
            this.throwError.sensorNotFound(sensor);
        }

        return ret;
    }

    getObjectByRid(rid) {
        let ret = null;

        this.getObjects().some(item => {
            if (item._rid === rid) {
                ret = item;
                return true;
            }
        });

        if (!ret) {
            this.throwError.objectNotFound(rid);
        }

        return ret;
    }

    getObjectsByType(type) {
        return this.getEnumerableObjects().filter(item => item.type === type);
    }

    removeObjectBySensor(sensor) {
        this.drawing.state[sensor] = null;

        this.getObjectBySensor(sensor).$el.remove();
    }

    removeObjectsByType(type) {
        this.getObjectsByType(type).forEach(item => {
            this.getObjectByRid(item._rid).$el.remove();
        });
    }

    draw(container, options = {}) {
        this.drawing.container = container;

        this.drawing.options = {
            ...{
                width: 230,
                height: 380,
                backgroundColor: '#fff',
                imagePath: 'images'
            },
            ...options
        }

        // SVG
        const $svg = $('<svg/>').attr({
            xmlns: 'http://www.w3.org/2000/svg',
            'xmlns:xlink': 'http://www.w3.org/1999/xlink',
            version: '1.1',
            width: this.drawing.options.width,
            height: this.drawing.options.height,
            viewBox: '0 0 230 500',
            class: 'tire-graphic-svg'
        });

        // Background Color
        $('<rect/>').attr({
            x: 0,
            y: 0,
            width: '100%',
            height: '100%'
        }).css({
            fill: this.drawing.options.backgroundColor
        }).addClass('tire-graphic-background')
          .appendTo($svg);

        // Background Image
        $('<image/>').attr({
            x: 0,
            y: 0,
            width: '100%',
            height: '100%',
            'xlink:href': `${this.drawing.options.imagePath}/${this.graphic.layout.image}`
        }).addClass('tire-graphic-background')
          .appendTo($svg);

        this.getObjects().forEach(item => {
            let $el = this.makeObjectGraph(item.type);

            switch (item.type) {
                case this.constructor.ObjectType.AIR_TANK:
                    let airTankOptions = {
                        ...{
                            size: 65,
                        },
                        ...item
                    }

                    $el.attr({
                        x: item.x,
                        y: item.y,
                        width: airTankOptions.size,
                        'data-sensor': item.sensor,
                        'data-axle': item.axle
                    });

                    break;
                
                case this.constructor.ObjectType.ATIS:
                    let atisOptions = {
                        ...{
                            size: 60,
                        },
                        ...item
                    }

                    $el.attr({
                        x: item.x,
                        y: item.y,
                        width: atisOptions.size,
                        'data-sensor': item.axle,
                        'data-axle': item.axle
                    });

                    $el.find('.in').attr({
                        'data-sensor': item.sensor[0],
                        'data-axle': item.axle
                    });

                    $el.find('.out').attr({
                        'data-sensor': item.sensor[1],
                        'data-axle': item.axle
                    });

                    break;

                case this.constructor.ObjectType.ECB:
                    let ecbOptions = {
                        ...{
                            size: 60,
                        },
                        ...item
                    }

                    $el.attr({
                        x: item.x,
                        y: item.y,
                        width: ecbOptions.size        
                    });
                    break;    

                case this.constructor.ObjectType.EURO_ATIS:
                    let euroAtisOptions = {
                        ...{
                            size: 60,
                        },
                        ...item
                    }

                    $el.attr({
                        x: item.x,
                        y: item.y,
                        width: euroAtisOptions.size,
                        'data-sensor': item.sensor
                    });

                    $el.find('.status').attr({
                        'data-sensor': item.sensor,
                        'data-axle': item.axle
                    });

                    break;

                case this.constructor.ObjectType.HUB_TEMP:
                    let hubTempOptions = {
                        ...{
                            size: 30
                        },
                        ...get(this.graphic, 'layout.defaults.hubTemp', {}),
                        ...item
                    }

                    $el.attr({
                        x: item.x,
                        y: item.y,
                        width: hubTempOptions.size,
                        'data-sensor': item.sensor,
                        'data-axle': item.axle
                    });

                    break;

                case this.constructor.ObjectType.TEXT:
                    let textOptions = {
                        ...{
                            font: 'Arial, sans-serif',
                            color: '#000',
                            style: 'normal',
                            size: 25,
                            rotation: 0
                        },
                        ...get(this.graphic, 'layout.defaults.text', {}),
                        ...item
                    }
                    
                    $el.attr({
                        x: item.x,
                        y: item.y,
                        transform: `rotate(${textOptions.rotation})`,
                        'transform-origin': 'center center'
                    }).css({
                        fontFamily: textOptions.font,
                        fontSize: `${textOptions.size}px`,
                        fontWeight: textOptions.style,
                        fill: textOptions.color
                    }).html(item.text);

                    break;
                
                case this.constructor.ObjectType.TIRE:
                    let tireOptions = {
                        ...{
                            color: '#000',
                            width: 20,
                            height: 60,
                            borderRadius: 5
                        },
                        ...get(this.graphic, 'layout.defaults.tire', {}),
                        ...item
                    }

                    $el.attr({
                        x: item.x,
                        y: item.y,
                        rx: tireOptions.borderRadius,
                        ry: tireOptions.borderRadius,
                        width: tireOptions.width,
                        height: tireOptions.height,
                        'data-sensor': item.sensor,
                        'data-axle': item.axle
                    });

                    break;

                default:
                    this.throwError.unknownObjectType(item.type);

            }

            $el.attr({
                'data-rid': item._rid
            });

            item.$el = $el;

            $svg.append($el);
        });
        
        this.drawing.container.innerHTML = $('<div/>').append($svg).html();

        // 😈
        // Remaps objects for its original elements, because when the SVG is forced to
        // redraw, the original elements are lost
        this.getObjects().forEach(item => {
            const object = item.$el.attr("data-tire-graphic-object");

            item.$el = $(this.drawing.container).find(`[data-tire-graphic-object='${object}']`);
        });

        this.isDrawn = true;
    }

    redraw(mode = 'redraw') {
        if (!this.isDrawn) {
            this.throwError.drawNotInitializedError();
        }

        this.draw(this.drawing.container, this.drawing.options);

        switch (mode) {
            case 'redraw':
                for (const [sensor, status] of Object.entries(this.drawing.state)) {
                    this.setSensorStatus(sensor, status);
                }

                break;

            case 'restore':
                for (const [sensor, status] of Object.entries(this.drawing.state)) {
                    this.setSensorStatus(sensor, status, 'ignore-removed');
                }

                break;

            case 'reset':
                break;

        }
    }

    restore() {
        this.redraw('restore');
    }

    reset() {
        this.redraw('reset');
    }

    getDrawOptions() {
        return this.drawing.options;
    }

    setSensorStatus(sensor, status, mode = 'default') {
        const object = this.getObjectBySensor(sensor);

        if (mode === 'default') {
            if (status === null) {
                object.$el.remove();
            }
        }

        switch (object.type) {
            case this.constructor.ObjectType.AIR_TANK:
                object.$el.find(".bg").css('fill', status);
                break;

            case this.constructor.ObjectType.ATIS:
                object.$el.find(`[data-sensor='${sensor}'] .bg`).css('fill', status);
                break;

            case this.constructor.ObjectType.EURO_ATIS:
                object.$el.find(".status").css('fill', status);
                break;

            case this.constructor.ObjectType.HUB_TEMP:
                object.$el.find(".bg").css('fill', status);
                break;
    
            case this.constructor.ObjectType.TIRE:
                object.$el.css('fill', status);
                break;
        }

        this.drawing.state[sensor] = status;
    }

    makeObjectGraph(type) {
        let html = null;

        switch (type) {
            case this.constructor.ObjectType.AIR_TANK:
                html = `
                    <svg viewBox="0 0 137 64" class="tire-graphic-object tire-graphic-air-tank tire-graphic-parent-element">
                        <path class="bg tank" style="fill: #bdbfc1" d="M38.32 0l60.85 0c16.6,0 30.35,12.83 31.83,29.07l5.78 0 0 5.8 -5.78 0c-1.48,16.24 -15.23,29.07 -31.83,29.07l-60.85 0c-16.6,0 -30.35,-12.83 -31.83,-29.07l-6.49 0 0 -5.8 6.49 0c1.48,-16.23 15.23,-29.07 31.83,-29.07z"></path>
                        <g>
                            <text x="30.88" y="39.73" style="fill: #373435; font-weight: bold; font-size: 16px; font-family: Arial, sans-serif">AIR TANK</text>
                            <text x="30.24" y="39.3"  style="fill: #fefefe; font-weight: bold; font-size: 16px; font-family: Arial, sans-serif">AIR TANK</text>
                        </g>
                    </svg>
                `;
                
                break;

            case this.constructor.ObjectType.ATIS:

                html = `
                    <svg viewBox="0 0 126 126" class="tire-graphic-object tire-graphic-atis tire-graphic-parent-element tire-graphic-has-sub">
                        <g class="in tire-graphic-sub">
                            <path class="bg" style="fill: #bdbfc1" d="M24.41 0l38.57 0 0 125.97 -38.57 0c-13.43,0 -24.41,-10.99 -24.41,-24.41l0 -77.14c0,-13.43 10.99,-24.41 24.41,-24.41z"/>
                            <path class="arrow" style="fill: #fefefe" d="M16.74 40.39l16.34 0 0 8.17 16.34 -16.34 -16.34 -16.34 0 8.17 -16.34 0 0 16.34zm8.17 0m8.17 4.09m8.17 -4.09m0 -16.34m-8.17 -4.09m-8.17 4.09m-8.17 8.17"/>
                            <text class="text" x="24.09" y="65.75" style="fill: #fefefe; font-family: Arial, sans-serif; font-weight: bold; font-size: 18px">IN</text>
                        </g>
                        <g class="out tire-graphic-sub">
                            <path class="bg" style="fill: #bdbfc1" d="M62.98 0l38.57 0c13.43,0 24.41,10.99 24.41,24.41l0 77.14c0,13.43 -10.99,24.41 -24.41,24.41l-38.57 0 0 -125.97z"/>
                            <path class="arrow" style="fill: #fefefe" d="M79.69 24.05l16.34 0 0 -8.17 16.34 16.34 -16.34 16.34 0 -8.17 -16.34 0 0 -16.34zm8.17 0m8.17 -4.09m8.17 4.09m0 16.34m-8.17 4.09m-8.17 -4.09m-8.17 -8.17"/>
                            <text class="text" x="76.62" y="65.75" style="fill: #fefefe; font-family: Arial, sans-serif; font-weight: bold; font-size: 18px">OUT</text>
                        </g>
                            
                        <line style="stroke: #fefefe; stroke-width: 0" x1="62.98" y1="0" x2="62.98" y2="125.97"/>

                        <g>
                            <text x="30.61" y="111.65" style="fill: #373435; font-family: Arial, sans-serif; font-weight: bold; font-size: 30px">ATIS</text>
                            <text x="29.41" y="110.85" style="fill: #fefefe; font-family: Arial, sans-serif; font-weight: bold; font-size: 30px">ATIS</text>
                        </g>
                    </svg>
                `;
                
                break;

                case this.constructor.ObjectType.ECB:
                    html = `
                        <svg version="1.1" id="layer" xmlns="http://www.w3.org/2000/svg" class="tire-graphic-object tire-graphic-ecb tire-graphic-parent-element tire-graphic-has-sub" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 65.4 50.6" style="enable-background:new 0 0 65.4 50.6;" xml:space="preserve">
                            <g class="in tire-graphic-sub">
                                <path class="bg" style="fill:#BDBFC1" d="M9.9,0.3h15.3v50H9.9c-5.3,0-9.7-4.4-9.7-9.7V10C0.2,4.6,4.6,0.3,9.9,0.3L9.9,0.3z"/>
                                <path class="arrow" style="fill:#FEFEFE" d="M6.8,16.3h6.5v3.2l6.5-6.5l-6.5-6.5v3.2H6.8C6.8,9.8,6.8,16.3,6.8,16.3z"/>
                                <text class="text" transform="matrix(1 0 0 1 9.7581 26.3398)" style="fill:#FEFEFE; font-family:'Arial'; font-weight: bold; font-size:7.1467px;">IN</text>
                            </g>
                            <g class="out tire-graphic-sub">
                                <path class="bg" style="fill:#BDBFC1" d="M25.2,0.3h15.3c5.3,0,9.7,4.4,9.7,9.7v30.6c0,5.3-4.4,9.7-9.7,9.7H25.2V0.3L25.2,0.3z"/>
                                <path class="arrow" style="fill:#FEFEFE" d="M31.8,9.8h6.5V6.6l6.5,6.5l-6.5,6.5v-3.2h-6.5V9.8z"/>
                                <text class="text" transform="matrix(1 0 0 1 30.6146 26.3398)" style="fill:#FEFEFE; font-family:'Arial'; font-weight: bold; font-size:7.1467px;">OUT</text>
                            </g>
                            <line x1="25.2" style="stroke: #fefefe; stroke-width: 0"  y1="0.3" x2="25.2" y2="50.3"/>
                            <g>
                                <text transform="matrix(1 0 0 1 12.8704 44.5645)" style="font-family:'Arial'; font-weight: bold; fill:#373435; font-size:11.9112px">ECB</text>
                                <text transform="matrix(1 0 0 1 12.3939 44.2461)" style="fill:#FEFEFE; font-family:'Arial'; font-weight: bold; font-size:11.9112px">ECB</text>
                            </g>
                            <g class="atis tire-graphic-sub">
                                <circle class="light" style="fill:#BDBFC1" cx="58.6" cy="10.4" r="6.6"/>
                                <text class="text" transform="matrix(1 0 0 1 53.0252 11.593)" style="fill:#FEFEFE; font-family:'Arial'; font-weight: bold; font-size:5.0602px">ATIS</text>
                            </g>
                            <g class="sys tire-graphic-sub">
                                <circle class="light" style="fill:#BDBFC1" cx="58.6" cy="26" r="6.6"/>
                                <text class="text" transform="matrix(1 0 0 1 53.5375 27.1932)" style="fill:#FEFEFE; font-family:'Arial'; font-weight: bold; font-size:5.0602px">SYS</text>
                            </g>
                        </svg>
                    `    
                break

                case this.constructor.ObjectType.EURO_ATIS:
                    html = `
                        <svg viewBox="0 0 277.51 277.51" class="tire-graphic-object tire-graphic-euro-atis tire-graphic-parent-element tire-graphic-has-sub">
                            <rect style="fill: #cccccc" x="0" y="0" width="277.51" height="277.51" rx="53" ry="53"/>
                            <circle class="status tire-graphic-sub" style="fill: #adadad" cx="68.72" cy="74.34" r="40.88"/>

                            <g>
                                <text style="font-weight: bold; font-size: 66px; font-family: Arial; fill: #373435" x="66" y="238.85">ATIS</text>
                                <text style="font-weight: bold; font-size: 66px; font-family: Arial; fill: #FEFEFE" x="63.35" y="237.08">ATIS</text>
                            </g>
                        </svg>
                    `;
                    
                break;

            case this.constructor.ObjectType.HUB_TEMP:
                html = `
                    <svg viewBox="0 0 52 52" class="tire-graphic-object tire-graphic-hub-temp tire-graphic-parent-element">
                        <rect class="bg" style="fill: #bdbfc1" width="51.7" height="51.7" rx="8.45" ry="8.45"/>
                        <path style="fill: #fefefe" d="M25.85 5.6c-3.57,0 -6.48,2.91 -6.48,6.48l0 9.06 -5.09 3.09 -7.87 0c-0.45,0 -0.81,0.36 -0.81,0.81 0,0.45 0.36,0.81 0.81,0.81l8.1 0c0.15,0 0.3,-0.05 0.43,-0.13l5.65 -3.42c0.02,-0.01 0.03,-0.02 0.05,-0.03l11.29 -6.86c0.02,-0.01 0.03,-0.02 0.05,-0.03l5.44 -3.32 7.87 0c0.45,0 0.81,-0.36 0.81,-0.81 0,-0.45 -0.36,-0.81 -0.81,-0.81l-8.1 0c-0.15,0 -0.3,0.05 -0.43,0.13l-4.43 2.68 0 -1.19c0,-3.57 -2.91,-6.48 -6.48,-6.48zm0 1.62c2.69,0 4.86,2.17 4.86,4.86l0 2.18 -9.72 5.9 0 -8.08c0,-2.69 2.17,-4.86 4.86,-4.86zm6.48 9.85l-12.96 7.87 0 2.35c-2.54,1.99 -4.05,5.05 -4.05,8.28 0,5.81 4.72,10.53 10.53,10.53 5.81,0 10.53,-4.72 10.53,-10.53 0,-3.23 -1.51,-6.28 -4.05,-8.28l0 -10.23z"/>
                    </svg>
                `;
                
                break;

            case this.constructor.ObjectType.TEXT:
                html = `
                    <text class="tire-graphic-object tire-graphic-text tire-graphic-no-parent"></text>
                `;
                
                break;
    
            case this.constructor.ObjectType.TIRE:
                html = `
                    <rect class="tire-graphic-object tire-graphic-tire tire-graphic-no-parent"/>
                `;
                
                break;

            default:
                this.throwError.unknownObjectType(type);
                
        }

        const template = document.createElement('template');

        template.innerHTML = html.trim();

        const $el = $(template.content.firstChild);

        $el.attr('data-tire-graphic-object', this.constructor.generateRid());

        return $el;
    }

    on(event, handler) {
        const $container = $(this.drawing.container);
        const svg = $container.children('svg')[0];

        const coordinates = e => {
            const pt = svg.createSVGPoint();

            if (e.touches && e.touches.length) {
                pt.x = e.touches[0].clientX;
                pt.y = e.touches[0].clientY;
            } else {
                pt.x = e.clientX;
                pt.y = e.clientY;
            }

            const coords = pt.matrixTransform(svg.getScreenCTM().inverse());

            return {
                x: coords.x,
                y: coords.y,
                origin: e.touches ? 'touch' : 'mouse',
                touch: e.touches && e.touches.length ? e.touches[0] : null
            }
        }

        if (event === 'backgroundclick') {
            $container.find('.tire-graphic-background').on('click', e => {
                handler(coordinates(e), e);
            });

            return false;
        }

        if (event.includes('mousemove') || event.includes('touchmove')) {
            if (event.includes('mousemove')) {
                $container.children('svg').on('mousemove', e => {
                    handler(coordinates(e), e);
                });
            }

            if (event.includes('touchmove')) {
                $container.children('svg').on('touchmove', e => {
                    handler(coordinates(e), e);
                });
            }

            return false;
        }

        $container.children('svg').on(event, e => {
            let item = null;

            let $target = $(e.target);
            let $el = $target;
            let $subEl = null;

            if (!$target.hasClass('tire-graphic-no-parent')) {
                $el = $target.closest('.tire-graphic-parent-element');

                if ($el.hasClass('tire-graphic-has-sub')) {
                    $subEl = $target.closest('.tire-graphic-sub');
                }
            }

            let sensor = null;

            if ($subEl) {
                sensor = Number($subEl.attr('data-sensor'));
            } else {
                sensor = Number($el.attr('data-sensor'));
            }

            let rid = $el.attr('data-rid');

            if (!rid) return false;

            item = this.getObjectByRid(rid);

            handler({
                item,
                $el,
                $subEl,
                sensor,
                pos: coordinates(e)
            }, e);
        });
    }
}