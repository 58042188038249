import AWS from 'aws-sdk'
import 'dotenv-flow/config'

import { getTokenData } from '@psitireinflation/sysadmin-auth-lib'

export function listFilesS3() {
  const credentials = new AWS.Credentials(getAwsCredentials())
   
  AWS.config.update({
    region: process.env.VUE_APP_REGION,
    credentials
  })
  
  const s3 = new AWS.S3()

  const compactJsonPath = process.env.VUE_APP_JSON_COMPACT_DESTINATION_PATH
  const fullJsonpath = process.env.VUE_APP_JSON_DESTINATION_PATH

  const params = {
    Bucket: process.env.VUE_APP_BUCKET_NAME,
    Key: compactJsonPath,
    ResponseCacheControl: 'no-cache'
  }

  return new Promise((resolve, reject) => {
    s3.getObject(params, (error, data) => {
      if (error) {
        reject(error)
        return
      }

      const fileContent = data.Body.toString('utf-8')
      const jsonArray = JSON.parse(fileContent)

      const layouts = jsonArray.map(item => {
        return {
          id: item.id,
          name: item.description,
          type: item.type,
          group: item.group,
          fullFilePath: `${fullJsonpath}${item.id}.tiregraphic.json`,
          visible: item.visible,
          iconClass: item.type === 'tractor' ? 'fas fa-truck' : 'fas fa-trailer'
        }
      })
      resolve(layouts)
    })
  })
}

export function listImagesS3() {
  const credentials = new AWS.Credentials(getAwsCredentials())

  AWS.config.update({
    region: process.env.VUE_APP_REGION,
    credentials,
  })

  const s3 = new AWS.S3()

  const imagesPath = process.env.VUE_APP_IMAGES_PATH

  const params = {
    Bucket: process.env.VUE_APP_BUCKET_NAME,
    Prefix: imagesPath
  }

  return new Promise((resolve, reject) => {
    s3.listObjectsV2(params, (error, data) => {
      if (error) {
        reject(error)
        return
      }

      const imageList = data.Contents.map((item) => {
        const fileNameWithExtension = item.Key
          .split('/')
          .pop()

        const fileNameWithoutExtension = fileNameWithExtension
          .split('/')
          .pop()
          .replace('.png', '')
        
        return {
          withExtension: fileNameWithExtension,
          withoutExtension: fileNameWithoutExtension,
          imagePath: item.Key
        }
      })

      resolve(imageList)
    })
  })
}

export function saveImageToS3(fileName, imageData) {
  const credentials = new AWS.Credentials(getAwsCredentials())

  AWS.config.update({
    region: process.env.VUE_APP_REGION,
    credentials
  })

  const s3 = new AWS.S3()

  const bucketName = process.env.VUE_APP_BUCKET_NAME
  const imagesPath = process.env.VUE_APP_IMAGES_PATH

  const destinationKey = `${imagesPath}/${fileName}`

  const params = {
    Bucket: bucketName,
    Key: destinationKey,
    Body: imageData,
    ContentType: 'image/png' 
  }

  return new Promise((resolve, reject) => {
    s3.upload(params, (error, data) => {
      if (error) {
        reject(error)
      } else {
        resolve(data)
      }
    })
  })
}

export function saveFileToS3(fileName, data, moveToTrash = false) {
  const credentials = new AWS.Credentials(getAwsCredentials())

  AWS.config.update({
    region: process.env.VUE_APP_REGION,
    credentials
  })

  const s3 = new AWS.S3()

  const bucketName = process.env.VUE_APP_BUCKET_NAME
  const destinationKey = moveToTrash ? process.env.VUE_APP_JSON_TRASH_PATH + fileName : fileName

  const params = { 
    Bucket: bucketName,
    Key: destinationKey,
    Body: data,
    ContentType: 'application/json'
  }

  return new Promise((resolve, reject) => {
    s3.upload(params, (e, data) => {
      if(e) {
        reject(e)
      }

      resolve(data)
    })
  })
}

export function moveFileToTrash(sourceKey, trashKey) {
  const credentials = new AWS.Credentials(getAwsCredentials())

  AWS.config.update({
    region: process.env.VUE_APP_REGION,
    credentials
  })

  const s3 = new AWS.S3()

  return new Promise((resolve, reject) => {
    s3.copyObject({
      Bucket: process.env.VUE_APP_BUCKET_NAME,
      CopySource: `${process.env.VUE_APP_BUCKET_NAME}/${sourceKey}`,
      Key: trashKey
    }, (copyErr) => {
      if(copyErr) {
        console.error('Erro ao copiar o arquivo para a lixeira', copyErr)
        reject(copyErr)
        return
      }
      s3.deleteObject({
        Bucket: process.env.VUE_APP_BUCKET_NAME,
        Key: sourceKey
      }, (deleteErr) => {
        if(deleteErr) {
          console.error('Erro ao exlucir o arquivo do caminho original: ', deleteErr)
          resolve(deleteErr)
          return
        }
        
        resolve()
      })
    })
  })
}

export function getAwsCredentials () {
  let accessKeyId = ""
  let secretAccessKey = ""

  const tokenPayload = getTokenData()

  if (tokenPayload) {
    const decodedAwsCredentials = atob(tokenPayload.aws.split('').reverse().join(''))
      .split('').reverse().join('')
    
    accessKeyId = decodedAwsCredentials.substring(0, 20)
    secretAccessKey = decodedAwsCredentials.substring(20)
  }

  if (process.env.VUE_APP_AWS_ACCESS_KEY_ID !== undefined) {
    accessKeyId = process.env.VUE_APP_AWS_ACCESS_KEY_ID
  }

  if (process.env.VUE_APP_AWS_SECRET_ACCESS_KEY !== undefined) {
    secretAccessKey = process.env.VUE_APP_AWS_SECRET_ACCESS_KEY
  }

  return { accessKeyId, secretAccessKey }
}

export function getNextAvailableId(allLayouts) {
  const allIds = allLayouts.map(layout => layout.id)
  let nextIdAvailable = 1
  for (let i = 1; i <= allIds.length + 1; i++) {
    if (!allIds.includes(i)) {
      nextIdAvailable = i
      break
    }
  }
  return nextIdAvailable
}
