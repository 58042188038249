import Vue from 'vue'
import Vuex from 'vuex'

import _ from 'lodash'

import TireGraphic from '../libs/TireGraphic'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    lastGraphicId: -1,
    graphic: {
      id: 0,
      version: 5,
      description: 'My Vehicle 1',
      visible: true,
      type: 'tractor',
      group: 'tractors',
      attachments: [],
      layout: {
        image: 'box-truck.png',
        defaults: {
          tire: {
            width: '',
            height: ''
          },
          hubTemp: {
            size: ''
          }
        },
        objects: [],
      },
      altLayout: {
        input: '',
        image: ''
      }
    },

    selectedObject: {}
  },

  mutations: {
    setGraphic(state, payload) {
      const graphic = payload

      if (!_.has(graphic, 'layout.defaults.tire.width')) {
        _.set(graphic, 'layout.defaults.tire.width', '')
      }

      if (!_.has(graphic, 'layout.defaults.tire.height')) {
        _.set(graphic, 'layout.defaults.tire.height', '')
      }

      if (!_.has(graphic, 'layout.defaults.tire.borderRadius')) {
        _.set(graphic, 'layout.defaults.tire.borderRadius', '')
      }

      if (!_.has(graphic, 'layout.defaults.hubTemp.size')) {
        _.set(graphic, 'layout.defaults.hubTemp.size', '')
      }

      if (!_.has(graphic, 'altLayout.input')) {
        _.set(graphic, 'altLayout.input', '')
      }

      if (!_.has(graphic, 'altLayout.image')) {
        _.set(graphic, 'altLayout.image', '')
      }
      
      graphic.layout.objects.forEach(item => {
        if (!item._rid) item._rid = TireGraphic.generateRid()
      })

      _.mergeWith(state.graphic, graphic, (value, newValue) => {
        if (_.isArray(value)) {
          return newValue
        }
      })

      if (!_.isEmpty(state.selectedObject) && !state.selectedObject.layout) {
        state.selectedObject = state.graphic.layout.objects.find(item => item._rid === state.selectedObject._rid)
      }
    },

    setSelectedObject(state, payload) {
      state.selectedObject = payload
    },
   
    setGraphicId(state, id = null){
      if(id != null){
        state.graphic.id = id
      }else{
        state.graphic.id = state.lastGraphicId
      }
    }
  },

  getters: {
    cleanGraphic(state) {
      const graphic = JSON.parse(JSON.stringify(state.graphic))

      if (_.has(graphic, 'layout.defaults.hubTemp.size')) {
        if (_.get(graphic, 'layout.defaults.hubTemp.size') === '') {
          _.unset(graphic, 'layout.defaults.hubTemp')
        }
      }

      if (_.has(graphic, 'layout.defaults.tire.height')) {
        if (_.get(graphic, 'layout.defaults.tire.height') === '') {
          _.unset(graphic, 'layout.defaults.tire.height')
        }
      }

      if (_.has(graphic, 'layout.defaults.tire.width')) {
        if (_.get(graphic, 'layout.defaults.tire.width') === '') {
          _.unset(graphic, 'layout.defaults.tire.width')
        }
      }

      if (_.has(graphic, 'layout.defaults.tire.borderRadius')) {
        if (_.get(graphic, 'layout.defaults.tire.borderRadius') === '') {
          _.unset(graphic, 'layout.defaults.tire.borderRadius')
        }
      }

      if (_.has(graphic, 'layout.defaults.tire')) {
        if (_.isEmpty(_.get(graphic, 'layout.defaults.tire'))) {
          _.unset(graphic, 'layout.defaults.tire')
        }
      }

      if (_.has(graphic, 'layout.defaults')) {
        if (_.isEmpty(_.get(graphic, 'layout.defaults'))) {
          _.unset(graphic, 'layout.defaults')
        }
      }

      if(_.has(graphic, 'altLayout.input')) {
        if(_.get(graphic, 'altLayout.input' ) === '') {
          _.unset(graphic, 'altLayout.input')
        }
      }

      if(_.has(graphic, 'altLayout.image')) {
        if(_.get(graphic, 'altLayout.image' ) === '') {
          _.unset(graphic, 'altLayout.image')
        }
      }

      graphic.layout.objects.forEach(item => {
        _.unset(item, 'index')
        _.unset(item, '$el')

        for (const [key, value] of Object.entries(item)) {
          if (value === '') _.unset(item, key)
        }
      })

      return graphic
    },

    superCleanGraphic(state, getters) {
      const graphic = JSON.parse(JSON.stringify(getters.cleanGraphic))

      graphic.description = graphic.description.replace(/[^a-zA-Z\s0-9()-/]+/g, "")
      
      graphic.layout.objects.forEach((item, index) => {
        _.unset(item, '_rid')
        item.$el = undefined
        
        item.name = item.name.replace(/[^a-zA-Z0-9\s]+/g, "")

        graphic.layout.objects[index] = _(item).toPairs().sortBy(0).fromPairs().value()
      })

      return graphic
    }
  }
})