<template>
  <div class="editor" ref="editor"></div>
</template>

<script>
import TireGraphic from '../libs/TireGraphic'

export default {
  name: 'Editor',

  props: {
    layout: {
      type: Object,
      required: true
    },
    width: {
      default: 600,
      required: false
    },
    height: {
      default: 600,
      required: false
    }
  },

  methods: {
    update(layout) {
      this.graphic.graphic = layout

      this.graphic.redraw()

      window.dispatchEvent(new Event('resize'))

      if (this.dragging.item) {
        this.selectObject(this.dragging.item)
      }
    },

    selectObject(object) {
      const item = this.graphic.getObjects().find(a => a._rid === object._rid)

      if (item) {
        this.dragging.item = item

        this.selectedObject = item
  
        this.graphic.getObjects().forEach(item => {
          item.$el.css({
            outline: 'none'
          })
        })
  
        item.$el.css({
          outline: '2px dashed var(--primary-color)',
          outlineOffset: '2px'
        })
      }
    }
  },

  data() {
    return {
      graphic: null,
      selectedObject: null,
      gridSize: 3,
      dragging: {
        active: false,
        item: null,
        offset: {
          x: 0,
          y: 0
        }
      }
    }
  },

  mounted() {
    const self = this

    let {width, height} = this

    this.graphic = new TireGraphic(this.layout)

    this.graphic.draw(this.$refs.editor, { 
      width,
      height,
      backgroundColor: 'var(--theme-color)',
      imagePath: 'https://tvlcdn.net/images/vehicle-layouts'
    })

    const Actions = {
      move(x, y) {
        if (self.selectedObject) {
          self.dragging.item.$el.attr({ x, y })
  
          self.dragging.item.x = x
          self.dragging.item.y = y
  
          self.$emit('objectmoved', { x, y })
        }
      },

      delete(item) {
        self.$emit('objectdeleted', item)

        self.dragging.item = null
        self.selectedObject = null
      }
    }

    window.addEventListener('keydown', e => {
      let x = 0
      let y = 0

      if (this.dragging.item) {
        x = this.dragging.item.x
        y = this.dragging.item.y
      }

      if (document.activeElement.tagName !== 'BODY') return

      if (!this.selectObject.layout) {
        switch (e.which) {
          case 38: // up            
            if (e.shiftKey) {
              Actions.move(x, y - 1)
            } else {
              Actions.move(x, y - this.gridSize)
            }
            
            break

          case 40: // down
            if (e.shiftKey) {
              Actions.move(x, y + 1)
            } else {
              Actions.move(x, y + this.gridSize)
            }
            
            break

          case 37: // left
            if (e.shiftKey) {
              Actions.move(x - 1, y)
            } else {
              Actions.move(x - this.gridSize, y)
            }
            
            break

          case 39: // right
            if (e.shiftKey) {
              Actions.move(x + 1, y)
            } else {
              Actions.move(x + this.gridSize, y)
            }
            
            break

          case 46: // delete
            Actions.delete(this.dragging.item)
            break

          case 9: // tab
            break
        }
      }
    })

    window.addEventListener('resize', () => {
      const options = this.graphic.getDrawOptions()

      options.width = document.querySelector('.editor-container').clientWidth - 40
      options.height = document.querySelector('.editor-container').clientHeight - 40

      this.graphic.redraw()

      if (this.dragging.item) {
        this.dragging.item.$el.css({
          outline: '2px dashed var(--primary-color)',
          outlineOffset: '2px'
        })
      }

      this.graphic.on('mousedown touchstart', (data, e) => {
        document.activeElement.blur()

        e.preventDefault()

        this.selectedObject = data.item

        this.$emit('objectselected', data.item)

        this.graphic.getObjects().forEach(item => {
          item.$el.css({
            outline: 'none'
          })
        })

        data.item.$el.css({
          outline: '2px dashed var(--primary-color)',
          outlineOffset: '2px'
        })

        this.dragging = {
          active: true,
          item: data.item,
          offset: {
            x: data.pos.x - data.item.x,
            y: data.pos.y - data.item.y
          }
        }
      })

      this.graphic.on('mouseup touchend', () => {
        this.dragging.active = false
        this.$emit('layoutchanged')
      })

      this.graphic.on('backgroundclick', () => {
        this.graphic.getObjects().forEach(item => {
          item.$el.css({
            outline: 'none'
          })
        })

        this.$emit('objectselected', this.graphic.graphic)

        this.selectedObject = null

        this.dragging.active = false
      })

      this.graphic.on('mousemove touchmove', mouse => {
        if (this.dragging.active) {
          let x = Math.ceil((mouse.x - this.dragging.offset.x) / this.gridSize) * this.gridSize
          let y = Math.ceil((mouse.y - this.dragging.offset.y) / this.gridSize) * this.gridSize

          Actions.move(x, y)
        }
      })
    })

    window.dispatchEvent(new Event('resize'))
  }
}
</script>

<style lang="less" scoped>
.editor {
  display: inline-block;
  background-color: @theme-color;

  &::v-deep {
    * {
      user-select: none;
    }

    .tire-graphic-object {
      cursor: all-scroll;
    }
  }
}
</style>
